<template>
    <div class="h-full" v-if="hackReset">
        <div v-show="!config.isDetailDisplay" :class="config.isDetailDisplay ? 'small-list' : 'scroll-auto'">
            <Control ref="control" :config="config" @onChangeEditDataSource="onChangeEditDataSource"></Control>
        </div>
        <div v-show="config.isDetailDisplay&&config.isNormal" class="detail">
            <EditControl ref="standardOrder" :dataSource="editDataSource" :config="config"></EditControl>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                hackReset: true,
                config: {
                    isDetailDisplay: false,
                    isNormal: false,
                    isReturn: false,
                    isAdd: false,
                    isCanEdit: false,
                    OrderDisplayTag: 0,
                    dataSourceKey:null,
                },
                editDataSource: {
                    OrderExtend: {},
                    ReceiverSource: {},
                    SenderSource: {},
                    OrderProductPage: {},
                    OrderReceiptPage: {}
                },
                editReturnDataSource: {
                    ReceiverSource: {},
                    SenderSource: {},
                    OriginOrderInfo: {},
                },
            }
        },
        methods: {
            init: function (obj) {
                this.config = {
                    isDetailDisplay: false,
                    isNormal: false,
                    isReturn: false,
                    isAdd: false,
                    isCanEdit: false,
                    OrderDisplayTag: 0
                };
                this.editDataSource = {
                    OrderExtend: {},
                    ReceiverSource: {},
                    SenderSource: {},
                    OrderProductPage: {},
                    OrderReceiptPage: {}
                };
                this.editReturnDataSource = {
                    ReceiverSource: {},
                    SenderSource: {},
                    OriginOrderInfo: {},
                };
            },
            onChangeEditDataSource: function (dataSourceKey) {
                this.config.dataSourceKey = dataSourceKey;
                if(!dataSourceKey || dataSourceKey<=0){
                    this.config.isAdd=true;
                    this.config.isCanEdit=true;
                    console.log(this.$refs)
                    this.$refs.standardOrder.add();
                }
                // if (dataSource.OrderStatus == 100) {
                //     this.config.isCanEdit = true;
                // }
                // else {
                //     this.config.isCanEdit = false;
                // }
                // if (this.config.isNormal) {
                //     this.editDataSource = dataSource;
                // } else if (this.config.isReturn) {
                //     this.editReturnDataSource = dataSource;
                // }
            }
        },
        components: {
            "EditControl": resolve => { require(['./components/editstandardorder.vue'], resolve) },
            "Control": resolve => { require(['./components/standardorder.vue'], resolve) },
        }
    }
</script>